<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the user details</h4>
      <vs-form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('firstName') && nurseData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('firstName')"
                v-model="nurseData.firstName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('lastName') && nurseData.lastName != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('lastName')"
                v-model="nurseData.lastName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('email') && nurseData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('email')"
                v-model="nurseData.email"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('contactNumber') &&
                  nurseData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                :danger-text="errors.first('contactNumber')"
                data-vv-as="contact number"
                label="Contact Number"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                class="w-full"
                v-model="nurseData.contactNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('password') && nurseData.password != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('password') ? true : false"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                name="password"
                data-vv-as="password"
                label="Password"
                icon-no-border
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('password')"
                autocomplete="new-password"
                v-model="nurseData.password"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('ahpraRegistrationNumber') &&
                  nurseData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('ahpraRegistrationNumber') ? true : false"
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('ahpraRegistrationNumber')"
                data-vv-as="AHPRA Registration Number"
                label="AHPRA Registration Number"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                class="w-full"
                v-model="nurseData.ahpraRegistrationNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">AHPRA Expiry date</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="nurseData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && nurseData.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraExiryDate")
              }}</span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                v-validate="'required'"
                v-model="nurseData.ahpraExiryDate"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceProvider') &&
                  nurseData.indemnityInsuranceProvider != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceProvider') ? true : false
                "
                v-validate="'required'"
                :danger-text="errors.first('indemnityInsuranceProvider')"
                name="indemnityInsuranceProvider"
                data-vv-as="indeminity insurance provider"
                label="Indemnity Insurance Provider"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="nurseData.indemnityInsuranceProvider"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceNumber') &&
                  nurseData.indemnityInsuranceNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceNumber') ? true : false
                "
                v-validate="'required'"
                name="indemnityInsuranceNumber"
                :danger-text="errors.first('indemnityInsuranceNumber')"
                data-vv-as="indemnity insurance number"
                label="Indemnity Insurance number"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="nurseData.indemnityInsuranceNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">Indemnity Insurance Expiry</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="nurseData.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('indemnityInsuranceExpiry') &&
                    nurseData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("indemnityInsuranceExpiry")
              }}</span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="nurseData.indemnityInsuranceExpiry"
              />
            </div>
          </vs-col>

          <vs-col
            id="aphraUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach AHPRA Certificate</label>
              <div
                :class="{ dateClass: !errors.has('ahpraCertificate') }"
                class=""
              >
                <div class="mb-4" v-if="nurseData.ahpraCertificate">
                  <div
                    v-if="
                      determineFileType(nurseData.ahpraCertificate) === 'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="nurseData.ahpraCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img :src="nurseData.ahpraCertificate" class="img-fluid" />
                  </div>
                </div>
                <div
                  :class="{ 'mt-3': nurseData.ahpraCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="ahpraId"
                    :class="{ 'six-pixel': errors.has('aphraCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'aphraUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            id="insuranceUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach Insurance Certificate</label>
              <div
                :class="{ dateClass: !errors.has('insuranceCertificate') }"
                class=""
              >
                <div class="mb-4" v-if="nurseData.insuranceCertificate">
                  <div
                    v-if="
                      determineFileType(nurseData.insuranceCertificate) ===
                      'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="nurseData.insuranceCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img
                      :src="nurseData.insuranceCertificate"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div
                  :class="{ 'm-3': nurseData.insuranceCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="insuranceId"
                    :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'insuranceUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("insuranceCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-row>
            <vs-col vs-type="flex" vs-align="left" vs-w="12">
              <div class="w-full m-5" align="right">
                <vs-button
                  :disabled="!validateForm"
                  class="mr-3"
                  @click="saveData"
                  icon-pack="feather"
                  >Onboard Nurse</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import vSelect from "vue-select";
import moment from "moment";
const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    ahpraCertificate: {
      required: "Please upload your AHPRA certificate file",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    insuranceCertificate: {
      required: "Please upload your insurance certificate file",
    },
    password: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
  },
};
Validator.localize("en", dict);
export default {
  name: "create-doctor",
  props: {
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
  },
  components: {
    "v-select": vSelect,
    Datepicker,
  },
  data: () => ({
    disabledDates: { to: new Date() },
    clinics: [],
    clinic: "",
    nurseData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      userType: "nurse",
      status: "Active",
      hasApprovedByAdmin: true,
    },
    items: [],
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.nurseData.firstName != "" &&
        this.nurseData.lastName != "" &&
        this.nurseData.contactNumber != "" &&
        this.nurseData.ahpraRegistrationNumber != "" &&
        this.nurseData.ahpraExiryDate != "" &&
        this.nurseData.indemnityInsuranceProvider != "" &&
        this.nurseData.indemnityInsuranceNumber != "" &&
        this.nurseData.indemnityInsuranceExpiry != "" &&
        this.nurseData.ahpraCertificate != "" &&
        this.nurseData.insuranceCertificate != ""
      );
    },
  },
  methods: {
    ...mapActions("nurse", ["createNurseBasic"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validate();
      if (this.nurseData.ahpraCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "ahpraCertificate",
          msg: "AHPRA Certificate is required",
        });
        document.getElementById("ahpraId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("ahpraId").style.borderColor = "#cccccc";
        self.errors.remove("ahpraCertificate");
      }
      if (this.nurseData.insuranceCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
        });
        document.getElementById("insuranceId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("insuranceId").style.borderColor = "#cccccc";
        self.errors.remove("insuranceCertificate");
      }

      if (isValid) {
        let payload = {};
        this.nurseData.emailVerifiedAt = new Date().toISOString();

        await this.createNurseBasic(this.nurseData)
          .then((res) => {
            self.$vs.notify({
              title: "Nurse Created",
              text: "Nurse created successfully.",
              color: "success",
            });
            if (user === "superAdmin") {
              self.$router.push("/super-admin/nurses");
            } else if (user === "subFranchise") {
              self.$router.push("/franchise/nurses");
            } else if (user === "subClinic") {
              self.$router.push("/clinic/nurses");
            } else {
              self.$router.push("/" + user + "/nurses");
            }
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err);
              self.$vs.notify({
                title: "Failed",
                text: "Failed to create nurse. Try again!",
                color: "danger",
              });
            }
            if (err.status === 409) {
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
              self.errors.add({
                field: "email",
                msg: err.data.message,
              });
            }
          });
      }
    },
    cancel() {
      this.$router.push({ name: "Nurses" });
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading ...",
        color: "blue",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.nurseData.ahpraCertificate = response.Location;
        this.errors.remove("aphraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.nurseData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }
      if (tab == "pp") {
        this.nurseData.profileImage = response.Location;
        this.$vs.loading.close("#profileUpload div div > .con-vs-loading");
      }

      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.nurseData.ahpraCertificate = "";
      if (tab == "i") this.nurseData.insuranceCertificate = "";
      if (tab == "pp") this.nurseData.profileImage = "";
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.7,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.nurseData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.errors.remove("ahpraCertificate");
            document.getElementById("ahpraId").style.borderColor = "#cccccc";
            self.nurseData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceId").style.borderColor =
              "#cccccc";
            self.nurseData.insuranceCertificate = res.data.Location;
          }
          if (key === "signatureUpload") {
            this.errors.remove("electronicSignature");
            document.getElementById("signatureId").style.borderColor =
              "#cccccc";
            self.nurseData.signature = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.nurseData.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.nurseData.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            self.nurseData.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            self.nurseData.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            self.nurseData.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            self.nurseData.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      if (str && str.length > 0) {
        var res = str.substring(str.length - 3, str.length);
        return res;
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    cancelHandler() {
      const user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/nurses");
      }
      if (user === "franchise") {
        this.$router.push("/franchise/nurses");
      }
      if (user === "clinic") {
        this.$router.push("/clinic/nurses");
      }
      if (user === "admin") {
        this.$router.push("/admin/nurses");
      }
    },
  },
  mounted(key = "profileImageUpload") {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
  created() {
    let self = this;
    this.fetchClinics({ type: this.fetchClinicsBy }).then((res) => {
      if (self.fetchClinicsBy !== "clinic") {
        this.clinics = res.data.data;
      } else {
        this.clinic = res.data.data.clinicId;
      }
    });
  },
};
</script>
<style lang="scss">
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}

.image-upload {
  cursor: pointer;
}
.avatar {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.style-chooser .vs__search::placeholder {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  letter-spacing: 0.01rem;
}
</style>
